
import {useRef, useState} from 'react'
import { Navbar } from './Navbar'


import emailjs from '@emailjs/browser';


const Contact = () => {

    const [status , setStatus] = useState("");
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_sise1bj', 'template_rg3dz3i', form.current, 'gT_gOA5lBtaFMtoJe')
          .then((result) => {
              setStatus("Message sent!")
          }, (error) => {
              setStatus("An error occurred, please try again")
          });
          e.target.reset()
      };

    return (
        <div className='containerDetail'>
            <main>
                <section className='contactImgPart'>
                    <Navbar />
                    <form ref={form} onSubmit={sendEmail} className="mailForm">
                    <div className='titleContact'>Contact us</div>
                        <div>
                        <div className="inputlabel">Subject:</div>
                        <input
                            placeholder="Subject"
                            name="subject"
                            type="text"
                            className="inputField"
                            required
                        />
                        <div className="inputlabel">Full name:</div>
                        <input
                            placeholder="John Doe"
                            name="name"
                            type="text"
                            className="inputField"
                            required
                        />
                        <div className="inputlabel">Email:</div>
                        <input
                            placeholder="johndoe@example.com"
                            name="email"
                            type="email"
                            className="inputField"
                            required
                        />
                        <div className="inputlabel">Message:</div>
                        <textarea
                            placeholder="Your message..."
                            name="text"
                            className="inputFieldBig"
                            required
                        />
                        <button
                            type="submit"
                            className="btnadd"
                        >
                            {' '}
                            SEND
                        </button>
                        <div className={status === "Message sent!" ? "green" : "red"}>{status}</div>
                        </div>
                    </form>
                </section>
            </main>
            
        </div>
    )
}

export default Contact;