import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
// import { NotFound } from "./components/NotFound";
import Landing from "./components/Landing";
import Detail from "./components/Detail";
import Contact from "./components/Contact";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      {<Route path="*" element={<Landing />} />}
      <Route path="/home" element={<Landing />} />
      <Route path="/detail/:id" element={<Detail />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
}

export default App;
